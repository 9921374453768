import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  setUserIsVegetarian,
  setUserPreferences,
  setUserPlanQuantity,
  setUserPlanType,
  resetUserPlanQuantity,
  getUserState
} from 'redux/reducers/user';
import { getCouponToBeApplied } from 'services/coupons';
import { setPlanSelectionCookie } from 'shared/utils/plan-selection-cookie';
import { getFoodPlans, getPlanVariations, getDefaultPlanForUser, getPlanVariationName } from 'shared/utils/plans';
import { setDefaultPlan, fetchPlans, getPlans } from 'redux/reducers/plans';
import { showError } from 'redux/reducers/notices';
import { getCoupons } from 'redux/reducers/coupons';

import { performTrack } from 'shared/utils/segment/utils';
import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { trackGTMPlansView, trackMealRegPlanId } from 'shared/utils/tracking-utils/registration';
import reportToRollbar from 'shared/utils/error-handling/report-to-rollbar/report-to-rollbar';
import PreppedAndReadyMiniPlanSelector from './PreppedAndReadyMiniPlanSelector';

const PreppedAndReadyMiniPlanSelectorContainer = ({ trackChangeQuantity, trackChangePlan, trackGetStartedClick }) => {
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const coupons = useSelector(getCoupons);
  const { defaultPlan, plansById } = useSelector(getPlans);
  const WINE_PLAN_ID = '4';

  const coupon = getCouponToBeApplied(coupons);
  const foodPlanVariations = getPlanVariations(getFoodPlans(plansById));

  useEffect(() => {
    const getPlansOnComponentMount = async () => {
      try {
        await dispatch(fetchPlans());
      } catch (error) {
        reportToRollbar(error);
        showError('planSelectorError', error.message, 'flash');
      } finally {
        trackGTMPlansView(foodPlanVariations, user, coupon);
        setIsLoadingInitialData(false);
      }
    };
    getPlansOnComponentMount();
  }, []);

  const getDefaultOptionInPlan = (planId) => {
    const plan = foodPlanVariations.find((p) => p.id === planId);
    if (plan) {
      return plan.defaultProductsPerOrder.toString();
    }
    return undefined;
  };

  const getSelectedPlanVariation = () => {
    const selectedPlanVariation = foodPlanVariations.find((variation) => {
      return (
        variation.id === ((user.plan.id !== WINE_PLAN_ID && user.plan.id) || defaultPlan.id) &&
        variation.display.planApiName === user.planApiName
      );
    });

    // this is not ideal but this is to safeguard against invalid planApi and user plan id combinations
    return !selectedPlanVariation ? getDefaultPlanForUser(foodPlanVariations) : selectedPlanVariation;
  };

  const handleSelectPlan = ({ id, isVegetarian, planApiName }, selectedPlanQuantity) => {
    if (user.plan.id !== id) {
      dispatch(setDefaultPlan(id));
      dispatch(setUserPlanType(id));
      dispatch(resetUserPlanQuantity());
    }
    if (user.isVegetarian !== isVegetarian) {
      dispatch(setUserIsVegetarian(isVegetarian));
    }
    if (user.planApiName !== planApiName) {
      dispatch(setUserPreferences(planApiName));
    }
    if (selectedPlanQuantity) {
      // add metric event here - quantity changed [mobile]
      dispatch(setUserPlanQuantity(selectedPlanQuantity));
    } else {
      const planQuantity = getDefaultOptionInPlan(id);
      if (planQuantity) {
        dispatch(setUserPlanQuantity(planQuantity));
      }
    }
    trackChangePlan({
      quantity: selectedPlanQuantity,
      planId: id,
      planType: id
    });
  };

  const handleChangeMealQuantity = (selectedPlanQuantity, planId) => {
    dispatch(setUserPlanQuantity(selectedPlanQuantity));
    trackChangeQuantity({
      quantity: selectedPlanQuantity,
      planId
    });
  };

  const handleContinueButtonClick = ({ id, planApiName, planType, price, planName, quantity, isVegetarian }) => {
    const planVariationName = getPlanVariationName(foodPlanVariations, planType, planApiName);
    setPlanSelectionCookie(id, quantity, planApiName);

    trackGetStartedClick({
      selectedPlanId: id,
      selectedPlanQuantity: quantity
    });

    // Google tag manager tracking
    trackMealRegPlanId({
      planType,
      price,
      quantity,
      isVegetarian,
      planName,
      user,
      coupon,
      planVariationName
    });

    performTrack({
      event: TRACK_EVENTS.mealPlanSelected,
      properties: {
        plan: {
          id,
          description: planApiName,
          meals_per_week: quantity,
          price
        }
      }
    });

    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'button',
        text: 'Continue',
        destination: '/users/sign_up',
        id: 'mini-plan-selector-continue'
      }
    });

    window.location = '/users/sign_up';
  };

  const selectedPlanVariation = getSelectedPlanVariation();

  return (
    <PreppedAndReadyMiniPlanSelector
      selectedPlanVariation={selectedPlanVariation}
      selectedPlanQuantity={user.plan.quantity || '2'}
      coupon={coupon}
      planVariations={foodPlanVariations}
      onSelectPlan={handleSelectPlan}
      isLoading={isLoadingInitialData || foodPlanVariations.length === 0}
      onSelectMealQuantity={handleChangeMealQuantity}
      onContinueButtonClick={handleContinueButtonClick}
    />
  );
};

PreppedAndReadyMiniPlanSelectorContainer.propTypes = {
  trackChangePlan: PropTypes.func,
  trackGetStartedClick: PropTypes.func,
  trackChangeQuantity: PropTypes.func
};

PreppedAndReadyMiniPlanSelectorContainer.defaultProps = {
  trackChangePlan: () => {},
  trackGetStartedClick: () => {},
  trackChangeQuantity: () => {}
};

export default PreppedAndReadyMiniPlanSelectorContainer;
